<script>
import {
    ArrowUpIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    PlayIcon
} from 'vue-feather-icons';


import Navbar from "@/components/navbar";
import Footer from "@/components/footer";


/**
 * Index-event component
 */
export default {
    data() {
        return {
             
        }
    },
    components: {
        Navbar,
        Footer,
       
        ArrowUpIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,

    },
    
    methods: {
       
    }
}
</script>

<template>
<div>
    <Navbar />


    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> {{ $t("message.mission") }} </h4>
                        <p >{{ $t("message.team1") }} </p>
                        <p >{{ $t("message.team2") }} </p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->


    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-light">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

          
    <!-- Team Start -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title">{{ $t("message.founders") }}</h4>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row " >
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card team text-center border-0">
                        <div class="position-relative">
                            <img src="images/jeon.jpeg" class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                            <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                             
                                <li class="list-inline-item ml-1"><a href="https://www.linkedin.com/in/landbox" target="_blank" class="rounded">
                                      <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                  </a></li>
                            </ul>
                            <!--end icon-->
                        </div>
                        <div class="card-body py-3 px-0 content">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{ $t("message.team11") }}</a></h5>
                            <small class="designation text-muted">{{ $t("message.team12") }}</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card team text-center border-0">
                        <div class="position-relative">
                            <img src="images/lee.jpeg" class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                            <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                      
                                <li class="list-inline-item ml-1"><a href="https://www.linkedin.com/in/ds4945" target="_blank"  class="rounded">
                                        <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                    </a></li>
                            </ul>
                            <!--end icon-->
                        </div>
                        <div class="card-body py-3 px-0 content">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{ $t("message.team13") }}</a></h5>
                            <small class="designation text-muted">{{ $t("message.team14") }}</small>
                        </div>
                    </div>
                </div>
                <!--end col-->
 
                <!--end col-->

                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Team Start -->

    <!--end section-->
    <Footer />
    <!-- Footer End -->
 
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
</style>
