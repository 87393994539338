<style>
.subscribe-container__submit {
    background-color: #2f3542;
    border-radius: 8px;
    color: #fff;
   
    font-size: 1rem;
    font-weight: 400;
    
    padding: 5px 5px;
    width: 100%;
}
.subscribe-container__submit:hover {
      background-color: #4a4f5a;
}
</style>
<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
  SendIcon,
  MessageCircleIcon,
  YoutubeIcon,
  FileTextIcon,
  BellIcon,
  MessageSquareIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
    SendIcon,
    MessageCircleIcon,
    YoutubeIcon,
    FileTextIcon,
    BellIcon,
    MessageSquareIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="images/logo_full_tight_white.png" height="35" alt="" />
            </a>
            <p class="mt-4">
              {{ $t("message.foot1") }}
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item ml-1">
                <a href="https://twitter.com/Landbox_" class="rounded" target="_blank">
                  <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://t.me/LandToken" class="rounded" target="_blank">
                  <bell-icon class="fea icon-sm fea-social"></bell-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://t.me/landtoken_en" class="rounded" target="_blank">
                  <send-icon class="fea icon-sm fea-social"></send-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                  <a href="https://t.me/landbox_kr" class="rounded" target="_blank">
                  <message-square-icon class="fea icon-sm fea-social"></message-square-icon >
                  </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://open.kakao.com/o/gvPKHplc" class="rounded" target="_blank">
                  <message-circle-icon class="fea icon-sm fea-social"></message-circle-icon >
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://bit.ly/3sLiiH0" class="rounded" target="_blank">
                  <youtube-icon class="fea icon-sm fea-social"></youtube-icon >
                </a>
              </li>
              
              <li class="list-inline-item ml-1">
                <a href="https://medium.com/landbox-news" class="rounded" target="_blank">
                  <file-text-icon class="fea icon-sm fea-social"></file-text-icon >
                </a>
              </li>

              
            </ul>
            <!--end icon-->
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">{{ $t("message.foot2") }}</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/page-terms" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>{{ $t("message.foot3") }}</router-link>
              </li>
              <li>
                <router-link to="/page-privacy" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>{{ $t("message.foot4") }}</router-link>
              </li>
              
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <form>
              <div class="row ml-1"> 
                <div class="foot-subscribe form-group">
                  <vue-mailchimp-email-signup-form 
                    :element-id="'email-signup-form'"
                    :url="'https://landbox.us1.list-manage.com/subscribe/post?u=293f5b9bb493de757cf6e2292&amp;id=7283c507ae'"
                    :title="$t('message.foot5')"
                    :subtitle="$t('message.foot6')"
                  />
                </div>
              </div>
            </form>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2020 - {{ new Date().getFullYear() }} Landbox Limited. Design with
                <i class="mdi mdi-heart text-danger"></i> by Love.
              </p>
            </div>
          </div>
          <!--end col-->

        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>
